
import './App.css';
import AppNavbar from './components/AppNavbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/About';

import TermsAndConditions from './components/TermsAndConditions';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivacyPolicy from './components/PrivacyPolicy';

function App() {
  return (
    <div >
       <Router>
      <AppNavbar/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/terms-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
      </Routes>
      </Router>
     
    </div>
  );
}

export default App;
