import React from "react";
import { Container } from "react-bootstrap";
import "./WhyUs.css";

const WhyUs: React.FC = () => {
  return (
    <div>
      <div className="why-us">
        <Container>
          <h2>Why choose Sana?</h2>
          <p>
            Sana is designed to generate highly personalized and relevant
            content for sales teams, founders, freelancers, and startups who are
            reaching out to prospects for the first time. By analyzing the
            online activities and social media behaviors of customers or
            clients, Sana tailors content based on factors such as company
            affiliation, job role, interests, and specific needs. This targeted
            approach ensures that the outreach is more engaging and effective.
            Additionally, our email marketing software enables users to
            efficiently reach multiple customers and clients simultaneously,
            streamlining the outreach process and maximizing impact.
          </p>
        </Container>
      </div>
      <div className="sub-content">
        <Container>
          <h2>Our Approach</h2>
          <p>
            A common mistake in email or any sales outreach is pitching a
            product or service to everyone, hoping for a purchase. While
            reaching a larger audience can be beneficial, indiscriminate
            pitching can significantly increase marketing costs without yielding
            good returns. It's more effective to target prospects and
            decision-makers based on their interests rather than pitching the
            product or service in the initial introduction, which often leads to
            disinterest.
          </p>
        </Container>
      </div>
    </div>
  );
};

export default WhyUs;
