import React from "react";
import { Container } from "react-bootstrap";
import "./Pricing.css";

const Pricing: React.FC = () => {
  return (
    <div className="pricing-card-container py-3" id="pricing">
      <Container>
        <h2 className="py-2">Pricing</h2>
        <p>
          Sana offers the most competitive pricing in the market, with a single
          standard cost that includes all features. We provide a 14-day trial
          period with no credit card required, allowing you to experience the
          full benefits of our platform risk-free.
        </p>
      </Container>
    </div>
  );
};

export default Pricing;
