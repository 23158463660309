import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./Footer.css";
import waveImage from "../images/footerWave.svg";

import { Link } from "react-router-dom";
const Footer: React.FC = () => {
  return (
    <div className="footer-main">
      <Image className="image" src={waveImage} />
      <div className="footer py-5">
        <Container>
          <Row>
            <Col cols="12" md="5" sm="12">
              <h4>Sana Outreach</h4>
              <p>
                Sana is an AI-driven content creation and email Outreach
                platform tailored for founders, sales teams, freelancers, and
                startups. Say goodbye to cold emails and hello to meaningful
                conversations that foster growth and drive new business.
              </p>
            </Col>
            <Col cols="12" md="3" sm="12">
              <div className="flex">
                <h5 className="title">Links</h5>
                <a href="/#about">About Us</a>
                <a href="/#pricing">Pricing</a>
                <a href="/#features">Features</a>
                <Link to="/terms-conditions">Terms & conditions</Link>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </div>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col className="bottom-footer">
              <h6 className="text-center pt-2">
                Copyright 2024 Sana Outreach, all rights reserved.
              </h6>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
