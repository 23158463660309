import React from "react";
import "./Features.css";
import { Container } from "react-bootstrap";

interface FeaturesItem {
  id: number;
  title: string;
  description: string;
}
const Features: React.FC = () => {
  const features: FeaturesItem[] = [
    {
      id: 1,
      title: "AI Content Creation",
      description:
        "Generate personalized email content based on prospects' activities and interests on social media.",
    },
    {
      id: 2,
      title: "Email DNS Records Setup",
      description:
        " We cover the setup of email DNS records (Domain Name System) and SMTP server (Simple Mail Transfer Protocol).",
    },
    {
      id: 3,
      title: "Unlimited Email Warm-ups",
      description:
        " Ensure your emails reach the inbox by gradually increasing your sending volume.",
    },
    {
      id: 4,
      title: "AI-Powered Dashboard",
      description:
        "Track your email performance with metrics like open rate, reply rate, unsubscribe rate, spam rate, bounce rate, and total emails sent daily, weekly, and monthly.",
    },
    {
      id: 5,
      title: "Unlimited Sequence Creation",
      description: "Create numerous email sequences to automate your outreach.",
    },
    {
      id: 6,
      title: "Bulk Prospect Uploads",
      description:
        "Upload and manage your prospects in bulk, keeping track of every single one.",
    },
    {
      id: 7,
      title: "Bulk Email Outreach",
      description:
        "Reach a large number of prospects with personalized emails.",
    },
    {
      id: 8,
      title: "Email Tracking",
      description: "Monitor the performance of your email campaigns.",
    },
    {
      id: 9,
      title: "Spam Word Detection",
      description:
        "Identify and remove spam-triggering words to improve deliverability.",
    },
    {
      id: 10,
      title: "Personalized Attachments",
      description:
        "Attach short videos, send gifts, and include voice recordings in emails to enhance personalization.",
    },
    {
      id: 11,
      title: "Email List Validation",
      description:
        "Validate your email recipients list before sending to reduce bounce rates.",
    },
    {
      id: 12,
      title: "AI-Powered A/B Testing",
      description:
        "Optimize your email content and subject lines with variations to achieve better open rates.",
    },
    {
      id: 13,
      title: "Global Unsubscribe Link",
      description:
        "Include an easy-to-find unsubscribe link to comply with regulations and maintain a clean email list.",
    },
    {
      id: 14,
      title: "Straightforward CTAs",
      description:
        " Use clear and effective call-to-action options to encourage quick replies from prospects.",
    },
  ];
  return (
    <div className="features my-5" id="features">
      <Container>
        <h2>Features</h2>
        <ul>
          {features.map((feature) => (
            <li key={feature.id}>
             <span>{feature.title}: </span> {feature.description}
            </li>
          ))}
        </ul>
      </Container>
    </div>
  );
};

export default Features;
