import React from "react";
import "./About.css";
import { Container } from "react-bootstrap";
import Footer from "./Footer";
import Pricing from "./Pricing";
import Features from "./Features";
import WhyUs from "./WhyUs";
import MissionAndVision from "./MissionAndVision";

const About: React.FC = () => {
  return (
    <div id="about">
      <div className="home-page">
        <Container className="text-center">
          <h1>
            The Ultimate 
            <br />
            <span className="animated-text">AI-Powered Content <br className="responsive" /> & Email Outreach</span>
          </h1>
          <p>
            Sana is an AI-driven content creation and email Outreach platform
            tailored for founders, sales teams, freelancers, and startups. Say
            goodbye to cold emails and hello to meaningful conversations that
            foster growth and drive new business.{" "}
          </p>
        </Container>
      </div>
      <WhyUs />
      <MissionAndVision/>
      <Features />
      <Pricing />
      <Footer />
    </div>
  );
};

export default About;
