import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import "./Terms.css";

import { useLocation } from "react-router-dom";

const TermsAndConditions: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const terms = [
    {
      title: "Software Usage Rights",
      subItems: [
        {
          title: "License Grant",
          description: `Sana grants the customer a non-exclusive, non-transferable, and 
                           non-sublicensable right to use the specified software and its documentation for the 
                           duration of the contract. The software is accessed via the Internet.`,
        },
        {
          title: "Usage Restrictions",
          description: `The customer must use the software only as per the contract 
                               and must not share it with or make it accessible to third parties. Reverse 
                              engineering, decompiling, disassembling, duplicating, or using any part of the 
                              software to create another application is prohibited.`,
        },
        {
          title: "Acknowledgement of Rights",
          description: `The customer acknowledges Sana as the sole 
                              licensor and owner of the software, its enhancements, trademarks, names, and 
                              patents. The customer must not remove or alter any copyright or proprietary 
                              notices.`,
        },
      ],
    },

    {
      title: "Data Rights",
      subItems: [
        {
          title: "Ownership and Access",
          description: `The customer retains sole ownership of all data collected, 
    processed, and generated by the software. Sana must provide access to this data 
    upon request, especially after contract termination, without any right of retention.`,
        },
        {
          title: "Responsibility",
          description: `The customer is responsible for ensuring the legality of data 
    collection, processing, and use, as well as protecting the rights of individuals 
    (information, use, correction, blocking, deletion). `,
        },
      ],
    },
    {
      title: "Violation of Terms",
      subItems: [
        {
          title: "Termination",
          description: `Sana may terminate the contract without notice if the terms of use are 
                            violated. Sana reserves the right to claim damages resulting from such breaches.`,
        },
      ],
    },
    {
      title: "Contract Duration and Termination",
      subItems: [
        {
          title: "Term and Renewal",
          description: `The minimum term for the SaaS service is one month, automatically renewing for the 
              same period unless terminated. Fees are collected 
                            via direct debit or credit card.`,
        },
        {
          title: "Termination by Sana",
          description: `Sana may terminate the contract for good cause, such as failed payments.`,
        },
        {
          title: "Cancellation",
          description: `Customers can cancel their plan anytime, retaining access until the 
    end of the billing period. Payments are non-refundable.`,
        },
      ],
    },
    {
      title: "Maintenance and Service Levels ",
      subItems: [
        {
          title: "Developments and Changes",
          description: `Sana may make technical changes and 
    improvements to the service. Significant changes will be communicated in 
    advance, allowing the customer to terminate the contract if significantly 
    disadvantaged.`,
        },
        {
          title: "System Operation",
          description: `Sana ensures the software operates in a suitable environment 
    and hardware configuration, including regular backups and necessary technical 
    measures (power supply, air conditioning, firewalls, etc.). `,
        },
        {
          title: "System Availability",
          description: ` The data center's network availability is 99% annually. The 
    customer is responsible for their Internet connection.`,
        },
      ],
    },
    {
      title: "Warranty",
      subItems: [
        {
          title: "Error Handling",
          description: `While errors cannot be entirely excluded, Sana guarantees the 
    software is generally usable and will fix reproducible errors free of charge. 
    Alternatives or new versions may be provided.`,
        },
        {
          title: "Exclusions",
          description: ` Warranty claims are excluded for non-contractual use or customer
    made changes. Claims are also excluded for indirect or economic losses.`,
        },
      ],
    },
    {
      title: "Limitation of Liability",
      subItems: [
        {
          title: "Liability Exclusions",
          description: ` Sana is not liable for telecommunications disruptions, 
    Internet line issues, force majeure, third-party faults, or customer errors. 
    Customers using Sana do so at their own risk regarding LinkedIn’s user agreement 
    violations, and Sana is held harmless from resulting claims. `,
        },
      ],
    },
    {
      title: "Compensation and Payment",
      subItems: [
        {
          title: "Fees",
          description: `Monthly fees are charged for Sana's services, invoiced in advance.`,
        },
        {
          title: "Payment Terms",
          description: ` Payments are made by direct debit or credit card. Services may be 
    restricted if payments are overdue. Offsetting claims against Sana is only allowed if 
    legally established or recognized by Sana.`,
        },
      ],
    },
    {
      title: "Confidentiality and Data Protection",
      subItems: [
        {
          title: "Confidentiality",
          description: `Both parties must keep all contract-related information 
    confidential, using it only for contract purposes. Exceptions include publicly 
    accessible information or court-ordered disclosures.`,
        },
        {
          title: "Data Protection",
          description: `Personal data processing must comply with statutory data 
    protection laws, and the data protection declaration applies.`,
        },
      ],
    },
    {
      title: "Final Provisions",
      subItems: [
        {
          title: "Jurisdiction and Governing Law",
          description: `The contract's place of performance and 
    jurisdiction is India, and Indian law exclusively applies.`,
        },
        {
          title: "Contract Validity",
          description: `If any provision is invalid, the rest of the contract remains valid. 
      The invalid provision will be replaced with one that closely matches the economic 
      intent. Any contract amendments must be in writing. `,
        },
      ],
    },
  ];
  return (
    <div className="terms my-5">
      <Container>
        <h3>General Terms and Conditions for Sana Outreach</h3>
        {terms.map((element) => (
          <div key={element.title} className="my-4">
            <h6>{element.title}</h6>
            <ul>
              {element.subItems.map((items) => (
                <li>
                  <strong>{items.title}: </strong>
                  <span>{items.description}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </Container>
    </div>
  );
};

export default TermsAndConditions;
