import React from "react";
import { Navbar, Nav, Container, Image } from "react-bootstrap";
import "./AppNavbar.css";

const AppNavbar:React.FC = () => {
  return (
    <div className="nav-bar-main">
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand href="/#about" className="flex">
            <Image className="logo" src="/images/sanaLogo.jpg" />
           <span>Sana</span> 
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">
              <a href='/#about'> <strong>About</strong></a>
              <a href='/#features'> <strong>Features</strong></a>
              <a href='/#pricing'> <strong>Pricing</strong></a>
            </Nav>
            <Nav className="ms-auto">
              <Nav.Link>
                {/* <Button className="btn">Login</Button> */}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default AppNavbar;
