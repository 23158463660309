import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "./MissionAndVision.css";

const MissionAndVision = () => {
  return (
    <div className="main">
      <Container className="mission d-flex justify-content-center pt-5">
        <Row>
          <Col xs={12} md={6} className="mb-4">
            <Card style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "5",
                }}>
              <Card.Body>
                <Card.Title className="text-center"><h3>Vision</h3> </Card.Title>
                <Card.Text className="description">
                  Our vision is to create the most engaging and advanced
                  outreach tools for sales teams, founders, small business
                  owners, freelancers, and new entrepreneurs.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6} className="mb-4" >
            <Card style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "5",
                }}>
              <Card.Body>
                <Card.Title className="text-center"><h3>Mission</h3></Card.Title>
                <Card.Text className="description">
                  Our mission is to enhance the ROI and email response rates for
                  our customers by initiating meaningful conversations,
                  minimizing spam and bounce rates, and improving email
                  deliverability. Sana is developed entirely in-house, without
                  relying on third-party tools.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MissionAndVision;
